import { Flex, Icon, Paragraph, ProgressBar } from '@components';
import { getRewardMessage, loyaltyStatusInfo } from '@utils/loyalty';
import { useIsMobile } from '@utils/hooks';
import styles from './RewardsDetail.module.scss';

export const RewardsDetail = ({ rewardDetail }) => {
	const isMobile = useIsMobile();

	const tierName = rewardDetail?.vip_tier_name || 'Spectator';
	const pointsBalance = rewardDetail?.points_balance || 0;
	const reward = getRewardMessage(pointsBalance);
	const pointsToNextTier = pointsBalance && reward.next ? (pointsBalance / reward.next) * 100 : 100;

	return (
		<div className={styles.container}>
			<header>
				<Flex gap={isMobile ? 4 : 5} align='center'>
					<Flex justify='center' align='center' gap={4}>
						<Icon shape='circle' label='loyalty-status-badge'>
							<img src={`/loyalty-${tierName.toLowerCase()}.svg`} alt='status' />
						</Icon>
						<Flex column className={styles.coloredText} style={{ color: loyaltyStatusInfo[tierName] }}>
							<Paragraph>{tierName.toUpperCase()}</Paragraph>
							<Paragraph>STATUS</Paragraph>
						</Flex>
					</Flex>
					<div className={styles.verticalSeparator} />
					<Paragraph className={styles.title}>
						{`You have `}
						<span style={{ color: loyaltyStatusInfo[tierName] }}>{pointsBalance}</span>
						{` Pair Points`}
					</Paragraph>
				</Flex>
			</header>
			<Flex column className={styles.content}>
				<Paragraph className={styles.subtitle}>
					<>
						{reward.message.split(reward.value)[0]}
						<span style={{ color: loyaltyStatusInfo[tierName] }}>{reward.value}</span>
						{reward.message.split(reward.value)[1]}
					</>
				</Paragraph>
				<Flex align='center'>
					<div style={{ width: '100%' }}>
						<ProgressBar width={`${pointsToNextTier}%`} height='16px' background={loyaltyStatusInfo[tierName]} />
					</div>
				</Flex>
				{reward.next && (
					<Paragraph
						className={styles.label}
					>{`${pointsBalance}/${reward.next} Pair Points until your next reward`}</Paragraph>
				)}
			</Flex>
		</div>
	);
};
