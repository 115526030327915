import { useRewards } from '@services/yotpo';
import AccountCard, { AccountCardProps } from '../AccountCard/AccountCard';
import styles from './RewardCard.module.scss';

const RewardCard = ({ userId, ...rest }: Omit<AccountCardProps, 'children'> & { userId: string }) => {
	const { data: rewards, isLoading } = useRewards(userId);

	if (!isLoading && !rewards) return null;

	return (
		<AccountCard
			className={styles.rewardContainer}
			primaryAction='rewards'
			secondaryAction='question'
			type='rewards'
			rewardDetail={rewards}
			isDataLoading={isLoading}
			{...rest}
		/>
	);
};

export default RewardCard;
