import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useRouter } from 'next/router';
import { BASE_FRAME_NAMES, LOCALE_DICT, MEDIUM_WIDTH } from '@constants';
import { Chevron, Flex, LabelText, Loading, Spacer, TopsCollectionPreview } from '@components';
import { useIsMobile, useLocalStorage, useWindowDimensions } from '@utils/hooks';
import { useCollectionsQuery } from '@utils/react-query';
import variables from '@styles/export.module.scss';
import styles from './SneakPeekCarousel.module.scss';

const SneakPeekCarousel = ({ collectionList }) => {
	const swiperRef = useRef(null);
	const [activeFrame] = useLocalStorage<(typeof BASE_FRAME_NAMES)[number]>('activeFrame', 'Larkin');
	const [frameShape, setFrameShape] = useState<(typeof BASE_FRAME_NAMES)[number]>(activeFrame);
	const [activeSlide, setActiveSlide] = useState(0);
	const { width: viewportWidth } = useWindowDimensions();
	const isMobile = useIsMobile();
	const slug = collectionList?.slug;
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const { data: collections, isLoading } = useCollectionsQuery(slug, {
		queryKey: slug,
		type: 'buildflow',
		withProducts: true,
		maxProducts: 10,
		skipVariants: true,
		includeSpecificFrameVariant: true,
		selectedOptions: [{ name: 'Frame', value: frameShape }],
		country: countryCode,
		queryRefreshVars: [locale],
	});

	const showButtons = collections?.length > 1;
	const isFirstElement = activeSlide === 0;
	const isLastElement = collections && activeSlide >= collections.length - 1;

	useEffect(() => {
		setFrameShape(activeFrame);
	}, [activeFrame]);

	if (!collections || isLoading)
		return (
			<div className={styles['loading-wrapper']}>
				<Loading className={styles['loading-spinner']} />
			</div>
		);

	return (
		<div className={styles.container}>
			<Swiper
				className={styles.swiper}
				direction='horizontal'
				modules={[Navigation]}
				autoplay={false}
				spaceBetween={16}
				centeredSlides={false}
				slidesPerView={1}
				navigation={viewportWidth > MEDIUM_WIDTH}
				onSlideChange={swiper => setActiveSlide(swiper.activeIndex)}
				onBeforeInit={swiper => {
					swiperRef.current = swiper;
				}}
			>
				{collections.map(collection => (
					<SwiperSlide key={collection.handle}>
						<TopsCollectionPreview collection={collection} productCount={4} />
					</SwiperSlide>
				))}
			</Swiper>
			{showButtons && (
				<>
					<Spacer size={isMobile ? 16 : 24} />
					<Flex align='center' justify='center' className={styles.control}>
						<Flex
							align='center'
							justify='center'
							className={cn(styles.button, { [styles.inactive]: isFirstElement })}
							onClick={() => swiperRef.current?.slidePrev()}
						>
							<Chevron direction='left' color={isFirstElement ? variables.gray3 : variables.blue2} />
							<Spacer size={8} />
							<LabelText style={{ color: isFirstElement ? variables.gray3 : variables.blue2 }}>
								{' '}
								Prev. Collection
							</LabelText>
						</Flex>
						<Spacer size={8} />
						<Flex
							align='center'
							justify='center'
							className={cn(styles.button, { [styles.inactive]: isLastElement })}
							onClick={() => swiperRef.current?.slideNext()}
						>
							<LabelText style={{ color: isLastElement ? variables.gray3 : variables.blue2 }}>
								Next Collection
							</LabelText>
							<Spacer size={8} />
							<Chevron direction='right' color={isLastElement ? variables.gray3 : variables.blue2} />
						</Flex>
					</Flex>
				</>
			)}
			<Spacer size={40} />
		</div>
	);
};

export default SneakPeekCarousel;
