import React, { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { CollectionSlider } from '@components';
import { useCollectionsQuery } from '@utils/react-query';
import { useLocalStorage } from '@utils/hooks';
import { BASE_FRAME_NAMES, LOCALE_DICT, TOP_FRAMES_PATH } from '@constants';
import { COLLECTION_LISTS } from '@constants/contentful';

type FeaturedCollectionSliderProps = {
	header: string;
	subheader: string;
	collectionSlug?: string;
	iconType?: 'heart' | 'star' | 'clock';
};

const FeaturedCollectionSlider = ({
	header,
	subheader,
	collectionSlug = COLLECTION_LISTS.LEAVING_SOON,
	iconType = 'clock',
	...rest
}: FeaturedCollectionSliderProps) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const safeSlug = rest?.collectionList?.slug || collectionSlug;
	const [activeFrame] = useLocalStorage<(typeof BASE_FRAME_NAMES)[number]>('activeFrame', 'Larkin');
	const [frameShape] = useState<(typeof BASE_FRAME_NAMES)[number]>(activeFrame);
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;

	const { data: collections, isLoading } = useCollectionsQuery(safeSlug, {
		queryKey: safeSlug,
		type: 'buildflow',
		withProducts: true,
		skipVariants: true,
		includeSpecificFrameVariant: true,
		selectedOptions: [{ name: 'Frame', value: frameShape }],
		country: countryCode,
		queryRefreshVars: [locale],
	});

	const products = useMemo(() => {
		if (collections) {
			const collection = Object.values(collections)[0];
			const { products: variants } = collection;
			return variants.map(variant => {
				return {
					...variant.product,
					variants: [variant],
				};
			});
		}
	}, [collections]);

	if (!collections && !isLoading) return null;

	const collectionInfo = collections
		? {
				backgroundUrl: collections[0].image?.url,
				title: `${collections[0].title} Collection`,
				link: `${TOP_FRAMES_PATH}/${collections[0].handle}`,
			}
		: null;

	return (
		<CollectionSlider
			iconType={iconType}
			header={header}
			subHeader={subheader}
			products={products}
			limit={8}
			featuredCollection={collectionInfo}
			isDataLoading={!collections || isLoading}
			sliderType={'leaving-soon'}
			{...rest}
		/>
	);
};

export default FeaturedCollectionSlider;
