import cn from 'classnames';
import React from 'react';
import Script from 'next/script';
import { BULK_ORDERS_FORM_SCRIPT } from '@constants';
import { Flex, Heading } from '@components';
import styles from './Typeform.module.scss';

type TypeformProps = {
	title: string;
	formId: string;
	className?: string;
};

const Typeform = ({ title, formId, className }: TypeformProps) => {
	return (
		<Flex column align='center' className={cn(styles.container, className)}>
			{title && (
				<Heading tag='h3' className={styles.title}>
					{title}
				</Heading>
			)}
			<div data-tf-live={formId} className={styles.form} />
			<Script src={BULK_ORDERS_FORM_SCRIPT} strategy='lazyOnload' data-handle='paireyewear' id='bulkOrdersTypeform' />
		</Flex>
	);
};

export default Typeform;
