import { useState, useEffect } from 'react';

const useAddScriptTag = (src: string, isEnabled = true) => {
	const [isScriptLoaded, setIsScriptLoaded] = useState(false);

	useEffect(() => {
		if (!isEnabled) {
			return;
		}

		if (document.querySelector(`script[src="${src}"]`)) {
			setIsScriptLoaded(true);
			return;
		}

		const scriptElement = document.createElement('script');
		scriptElement.src = src;
		scriptElement.defer = true;
		scriptElement.addEventListener('load', () => setIsScriptLoaded(true));
		document.body.appendChild(scriptElement);
	}, [isEnabled, src]);

	return isScriptLoaded;
};

export default useAddScriptTag;
