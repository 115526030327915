import AccountCard, { AccountCardProps } from '../AccountCard/AccountCard';

const OrderTracking = ({ order, ...rest }: Omit<AccountCardProps, 'children'>) => {
	if (!order) return null;

	return (
		<AccountCard
			order={order}
			type='tracking'
			title='ORDER TRACKER'
			label={order.last_status.title}
			subtitle={`ORDER #${order?.order_number}`}
			subCopy={order.last_status.description}
			{...rest}
		/>
	);
};

export default OrderTracking;
