import { useEffect, useRef } from 'react';

type CallbackFunction = () => void;

type IntervalRef = {
	current: CallbackFunction;
};

function useInterval(callback: CallbackFunction, delay) {
	const savedCallback = useRef() as IntervalRef;

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

export default useInterval;
