import { useEffect } from 'react';
import { YOTPO } from '@constants';
import { useAddScriptTag } from '@utils/hooks';

const initYotpoWidgets = () => {
	if (window.yotpoWidgetsContainer?.initWidgets) {
		window.yotpoWidgetsContainer.initWidgets();
	}
};

const useLoadYotpo = (isEnabled = true) => {
	const isScriptLoaded = useAddScriptTag(YOTPO.REWARDS_SCRIPT_URL, isEnabled);

	useEffect(() => {
		if (!isEnabled) {
			return;
		}

		if (!isScriptLoaded) {
			return;
		}

		initYotpoWidgets();
	}, [isEnabled, isScriptLoaded]);
};

export { useLoadYotpo, initYotpoWidgets };
