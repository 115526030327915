import { FC } from 'react';
import CountdownDigit from './CountdownDigit';
import styles from './CountdownDigitGroup.module.scss';

type CountdownDigitGroupProps = {
	value: number;
	unit: string;
};

const CountdownDigitGroup: FC<CountdownDigitGroupProps> = ({ value, unit }) => {
	if (value === undefined) {
		// Empty State
		return (
			<div className={styles.digitGroup}>
				<div />
				<div>{unit}</div>
			</div>
		);
	}

	const digitsArray = value.toString().split('');
	const isTwoDigits = digitsArray.length === 2;

	const tensPlace = isTwoDigits ? parseInt(digitsArray[0], 10) : 0; // add a leading zero if applicable
	const onesPlace = isTwoDigits ? parseInt(digitsArray[1], 10) : parseInt(digitsArray[0], 10);

	return (
		<div className={styles.digitGroup}>
			<div>
				<CountdownDigit digit={tensPlace} />
				<CountdownDigit digit={onesPlace} />
			</div>
			<div>{unit}</div>
		</div>
	);
};

export default CountdownDigitGroup;
