import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const PlusSign = () => {
	return (
		<AccessibleIcon.Root label='Plus Sign'>
			<img alt='plus sign' src='/PlusSign.png' width={59} height={59} />
		</AccessibleIcon.Root>
	);
};

export default PlusSign;
