import { OrderDetailPOMS } from '@ts/poms/orders';
import { Caption, Flex, Heading, Paragraph } from '@components';
import variables from '@styles/export.module.scss';
import { ProductList } from './ProductList';

type OrderDetailProps = {
	order: OrderDetailPOMS;
};

export const OrderDetail = ({ order }: OrderDetailProps) => {
	const { line_items, total_price } = order;

	return (
		<div>
			<ProductList items={line_items} />
			<Flex justify='between' align='baseline'>
				<Flex gap={2} align='baseline'>
					<Heading style={{ fontSize: '1.6rem' }}>Total</Heading>
					<Caption small color={variables.gray4}>
						{`(${line_items.length} items)`}
					</Caption>
				</Flex>
				<Flex gap={2} align='baseline'>
					<Caption small color={variables.gray4}>
						(Tax Included)
					</Caption>
					<Paragraph style={{ fontWeight: '500' }}>{total_price}</Paragraph>
				</Flex>
			</Flex>
		</div>
	);
};
