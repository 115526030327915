import { CONTENTFUL_ANNOUNCEMENT_BAR_NAME } from '@constants/contentful';
import { getAnnouncementBar, getMenus } from '@services/contentful/operations';
import { AnnouncementBar, Menu } from '@ts/contentful';

type GlobalProps = { deferHeader?: boolean; locale: string } | Record<string, never>;
export type GlobalPropsReturn = { announcements: AnnouncementBar; footer: Menu; header: Menu };

/** Handles data-fetching for any information that needs to accessed on every page of the app.
 *  Remove this once Next.js 13 `app` directory is out of [beta](https://beta.nextjs.org/docs/app-directory-roadmap)
 */
const getGlobalProps = async ({ deferHeader = false, locale = 'en-US' }: GlobalProps = {}): Promise<GlobalPropsReturn> => {
	const announcements = await getAnnouncementBar({
		slug: CONTENTFUL_ANNOUNCEMENT_BAR_NAME,
	});
	const footer = (await getMenus({ menuType: 'Footer', locale })) as Menu;
	const header = deferHeader ? null : ((await getMenus({ menuType: 'Header', locale })) as Menu);

	return {
		announcements,
		footer,
		header,
	};
};

export { getGlobalProps };
