import { formatDateLocale } from '@utils/dates';

export const getArticleDate = (date): string => {
	return formatDateLocale(date, 'en', {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	})
		.toString()
		.replace('at', '•');
};
