import React, { useEffect, useState } from 'react';
import { NormalizedProduct } from '@ts/product';
import { Grid, ProductGrid, VerticalCard } from '@components';
import { BaseFrameProductList as BaseFrameProductListProps } from '@ts/contentful';
import { getAllBaseFrames } from '@services/contentful';

const BaseFrameProductList = ({ baseFrames }: BaseFrameProductListProps) => {
	const [baseFrameProducts, setBaseFrameProducts] = useState([]);

	useEffect(() => {
		(async () => {
			const response = (await getAllBaseFrames(undefined, undefined, baseFrames)) as NormalizedProduct[];
			setBaseFrameProducts(response);
		})();
	}, [baseFrames]);

	return (
		<div>
			<Grid>
				<ProductGrid type='base' style={{ width: '100%' }}>
					{baseFrameProducts.map(p => (
						<VerticalCard
							key={p.handle}
							product={p}
							variant={p.variants[0]}
							primaryAction='view'
							showVariantControls
						/>
					))}
				</ProductGrid>
			</Grid>
		</div>
	);
};

export default BaseFrameProductList;
