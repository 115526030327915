import { memo, useMemo, ReactNode } from 'react';
import cn from 'classnames';
import { MEDIUM_MAX_WIDTH } from '@constants';
import { ContentfulComponentMap } from '@components';
import { useHasMounted, useIsMobile } from '@utils/hooks';
import { TwoPanelContentCardFields } from '@ts/contentful';
import styles from './TwoPanelContentCard.module.scss';

/**
 * TwoPanelContentCard
 *
 * @contentType componentTwoPanelContentCard
 * @resource https://app.contentful.com/spaces/a8mjl2rcjwa7/content_types/componentTwoPanelContentCard/fields
 *
 */
export const TwoPanelContentCard = ({
	backgroundColor: backgroundColorDesktop,
	backgroundColorMobile,
	videoUrl,
	contentSection,
	imageDesktop,
	imageMobile,
	flipImageSide = false,
	forceMobileStyle = false,
	imageHeight = 'auto',
	objectFit = 'contain',
	borderRadius = 'None',
	buttonAlignment = 'center',
	children,
}: TwoPanelContentCardFields & { children?: ReactNode }) => {
	const isMobile = useIsMobile({ maxWidth: MEDIUM_MAX_WIDTH });
	const hasMounted = useHasMounted();
	const isMobileMounted = isMobile && hasMounted;
	const image = isMobileMounted ? imageMobile : imageDesktop;
	const backgroundColor = isMobileMounted ? (backgroundColorMobile ?? backgroundColorDesktop) : backgroundColorDesktop;
	const isVideo = image.contentType === 'video/mp4';

	const contentComponent = useMemo(
		() => contentSection && <ContentfulComponentMap isMobile={isMobile} blocks={contentSection} />,
		[isMobile, contentSection]
	);
	const assetComponent = useMemo(() => {
		return isVideo ? (
			<video playsInline autoPlay muted loop preload='auto' style={{ width: '100%', height: '100%' }}>
				<source src={videoUrl} type='video/mp4' />
				<source src={image.url} type='video/mp4' />
				Your browser does not support the video tag.
			</video>
		) : (
			<img src={image.url} alt={image.title} className={styles.image} width={image.width} height={image.height} />
		);
	}, [image]);

	return (
		<div
			className={cn(styles['container'], {
				[styles['flipped']]: flipImageSide,
				[styles['force-mobile-style']]: forceMobileStyle,
				[styles['cover']]: objectFit === 'cover',
				[styles['contain']]: objectFit === 'contain',
				[styles['auto']]: imageHeight === 'auto',
				[styles['small']]: imageHeight === 'small',
				[styles['medium']]: imageHeight === 'medium',
				[styles['large']]: imageHeight === 'large',
				[styles['br-1']]: borderRadius === 'X-Small',
				[styles['br-2']]: borderRadius === 'Small',
				[styles['br-3']]: borderRadius === 'Medium',
				[styles['br-4']]: borderRadius === 'Large',
			})}
			style={{ backgroundColor }}
		>
			<div className={styles['image-container']}>{assetComponent}</div>

			<div className={cn(styles['content-container'], styles[`button-${buttonAlignment}`])}>
				{!!children ? children : contentComponent}
			</div>
		</div>
	);
};

export default memo(TwoPanelContentCard);
