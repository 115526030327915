import { normalizeContentfulEntry } from '@utils/normalizers';
import { Menu } from '@ts/contentful';
import { fetchContentful } from '../client';

export async function getMenus({ menuType = 'Header', locale = 'en-US' }) {
	const menu = await fetchContentful<Menu>({
		'content_type': 'menus',
		'limit': 1,
		'include': 3,
		'fields.menuType': menuType,
		'fields.setLive': true,
		...(locale && { locale }),
	});

	return await normalizeContentfulEntry<Menu>(menu);
}
