export const loyaltyStatusInfo = {
	Spectator: '#F38BB6',
	Sightseer: '#84D0FC',
	Eyecon: '#FF510E',
	Visionary: '#FBE324',
};

type RewardMessage = {
	message: string;
	value: string;
	next?: number;
};

const rewardMessages: { [key: string]: RewardMessage } = {
	'<50': { message: 'You are so close. 50 points earns you a $5 discount', value: '$5', next: 50 },
	'50-99': { message: 'You have $5 to use towards your next purchase', value: '$5', next: 100 },
	'100-199': { message: 'You have $10 to use towards your next purchase', value: '$10', next: 200 },
	'200-299': { message: 'You have $20 to use towards your next purchase', value: '$20', next: 300 },
	'300-399': { message: 'You have $30 to use towards your next purchase', value: '$30', next: 400 },
	'400-499': { message: 'You have $40 to use towards your next purchase', value: '$40', next: 500 },
	'500-599': { message: 'You have $50 to use towards your next purchase', value: '$50', next: 600 },
	'600-999': { message: 'You have +$60 to use towards your next purchase', value: '+$60', next: 1000 },
	'1000+': { message: 'You have +$100 to use towards your next purchase', value: '+$100' },
};

export function getRewardMessage(points: number): RewardMessage {
	if (points < 50) return rewardMessages['<50'];
	if (points >= 50 && points < 100) return rewardMessages['50-99'];
	if (points >= 100 && points < 200) return rewardMessages['100-199'];
	if (points >= 200 && points < 300) return rewardMessages['200-299'];
	if (points >= 300 && points < 400) return rewardMessages['300-399'];
	if (points >= 400 && points < 500) return rewardMessages['400-499'];
	if (points >= 500 && points < 600) return rewardMessages['500-599'];
	if (points >= 600 && points < 1000) return rewardMessages['600-999'];
	return rewardMessages['1000+'];
}
