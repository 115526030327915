import { useState } from 'react';
import { useInterval } from '@utils/hooks';
import { Flex } from '@components';
import CountdownDigitGroup from './CountdownDigitGroup';
import styles from './Countdown.module.scss';

interface CountdownProps {
	date: string;
	text: string;
}

const Countdown = ({ date, text }: CountdownProps) => {
	const [remaining, setRemaining] = useState(null);
	const [isCountdownFinished, setCountdownFinished] = useState(false);

	useInterval(
		() => {
			const microsecondsRemaining = +new Date(date) - +new Date();
			const isLaunchDateInTheFuture = microsecondsRemaining > 0;

			if (isLaunchDateInTheFuture) {
				setRemaining({
					days: Math.floor(microsecondsRemaining / (1000 * 60 * 60 * 24)),
					hours: Math.floor((microsecondsRemaining / (1000 * 60 * 60)) % 24),
					minutes: Math.floor((microsecondsRemaining / 1000 / 60) % 60),
					seconds: Math.floor((microsecondsRemaining / 1000) % 60),
				});
			} else {
				setRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
				setCountdownFinished(true);
			}
		},
		isCountdownFinished ? null : 1000
	);

	return (
		<section className={styles.countdown}>
			{text && <header>{text}</header>}
			<Flex fullWidth justify='between' align='center'>
				<CountdownDigitGroup value={remaining?.days} unit='Days' />
				<span>:</span>
				<CountdownDigitGroup value={remaining?.hours} unit='Hours' />
				<span>:</span>
				<CountdownDigitGroup value={remaining?.minutes} unit='Minutes' />
				<span>:</span>
				<CountdownDigitGroup value={remaining?.seconds} unit='Seconds' />
			</Flex>
		</section>
	);
};

export default Countdown;
