import { useRef, useEffect, FC } from 'react';
import styles from './CountdownDigit.module.scss';

type CountdownDigitProps = {
	digit: number;
};

type PreviousDigitRef = {
	current: number;
};

const CountdownDigit: FC<CountdownDigitProps> = ({ digit }) => {
	const prevDigit = useRef() as PreviousDigitRef;

	useEffect(() => {
		prevDigit.current = digit;
	}, [digit]);

	return (
		<div key={digit} className={styles.digit}>
			<span>{digit}</span>
			<span>{prevDigit.current}</span>
		</div>
	);
};

export default CountdownDigit;
