import React from 'react';
import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
	width: string;
	height?: string;
	background?: string;
};

const ProgressBar = ({ width, height = '4px', background = '#3431dc' }: ProgressBarProps) => {
	return (
		<div className={styles.container} style={{ height }}>
			<div className={styles.bar} style={{ width, height, background }}></div>
		</div>
	);
};

export default ProgressBar;
