import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const EqualSign = () => {
	return (
		<AccessibleIcon.Root label='Equals Sign'>
			<img alt='equal sign' src='/EqualsSign.png' width={59} height={59} />
		</AccessibleIcon.Root>
	);
};

export default EqualSign;
