import { AnnouncementBar, AnnouncementBarFields } from '@ts/contentful/announcement';
import client from '../client';

type GetAnnouncementBarParams = {
	slug: string;
};

export async function getAnnouncementBar(params: GetAnnouncementBarParams) {
	const { items } = await client.getEntries({
		'content_type': 'announcementBar',
		'limit': 1,
		'include': 1,
		'fields.slug': params.slug,
	});

	const announcementBarFields = items[0]['fields'] as AnnouncementBarFields;
	if (!announcementBarFields) return null;

	const announcementBar: AnnouncementBar = {
		isEnabled: announcementBarFields.showAnnouncementBar,
		showOnHomepageOnly: announcementBarFields.showOnHomepageOnly,
		backgroundColor: announcementBarFields.announcementBarColor,
		textColor: announcementBarFields.textColor,
		slides: announcementBarFields.announcementSlide?.map(entry => {
			const {
				display: message_country = null,
				emoji: message_emoji = null,
				text: message_text = null,
				link: message_link = null,
				openNewWindow: message_link_target = null,
				showLinkIcon: message_link_icon = null,
			} = entry.fields;

			return {
				message_country,
				message_emoji,
				message_text,
				message_link,
				message_link_target,
				message_link_icon,
			};
		}),
	};

	return announcementBar;
}
