import React, { MutableRefObject, forwardRef, useEffect, useRef, useState } from 'react';
import { InstantSearch, useRefinementList } from 'react-instantsearch';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Chevron, ComponentHeader, FilterCard, Flex, Tabs, TypographyButton } from '@components';
import { ALGOLIA_SEARCH_INDEXES, RefinementItem } from '@utils/algolia';
import { algoliaClient } from '@services/algolia/client';
import { useLocalStorage } from '@utils/hooks';
import { LOCALE_DICT, SHOP_BY_COMPONENT_COPY } from '@constants';
import styles from './LoggedInShopBy.module.scss';

type LoggedInShopByContentProps = {
	data: RefinementItem[];
	frameType: 'color' | 'design';
};

const LoggedInShopBy = () => {
	const [currentTab, setCurrentTab] = useLocalStorage('shopByTab', 'color');
	const [activeTab, setActiveTab] = useState(currentTab);
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const handleTabChange = (value: string) => {
		setActiveTab(value);
		setCurrentTab(value);
	};

	return (
		<InstantSearch
			indexName={ALGOLIA_SEARCH_INDEXES[countryCode].RECENTLY_ORDERED}
			searchClient={algoliaClient}
			future={{
				preserveSharedStateOnUnmount: false,
			}}
		>
			<Tabs
				data-tab-carousel
				value={activeTab}
				onValueChange={handleTabChange}
				activationMode='manual'
				className={styles.container}
			>
				<ComponentHeader
					className={styles.homepagePadding}
					title={SHOP_BY_COMPONENT_COPY[activeTab].title}
					subtitle={SHOP_BY_COMPONENT_COPY[activeTab].subtitle}
				>
					<Tabs.List>
						<Tabs.Trigger value='color' data-tab-carousel-tab={'color'}>
							<TypographyButton>Color</TypographyButton>
						</Tabs.Trigger>
						<Tabs.Trigger value='design' style={{ margin: 0 }} data-tab-carousel-tab={'design'}>
							<TypographyButton>Design</TypographyButton>
						</Tabs.Trigger>
					</Tabs.List>
				</ComponentHeader>
				<TabContent />
			</Tabs>
		</InstantSearch>
	);
};

// This is the actual component that will be rendered
const TabContent = () => {
	const ref = useRef<HTMLDivElement>(null);
	const { items: colorItems } = useRefinementList({
		attribute: 'meta.custom_fields.colors',
		operator: 'or',
		limit: 30,
	});
	const { items: designItems } = useRefinementList({
		attribute: 'meta.custom_fields.design',
		operator: 'or',
		limit: 30,
	});
	return (
		<>
			<Tabs.Content value='color' removeDefaultStyles>
				{colorItems && <LoggedInShopByContent data={colorItems} frameType='color' ref={ref} />}
			</Tabs.Content>
			<Tabs.Content value='design' removeDefaultStyles>
				{designItems && <LoggedInShopByContent data={designItems} frameType='design' ref={ref} />}
			</Tabs.Content>
		</>
	);
};

const LoggedInShopByContent = forwardRef<HTMLDivElement, LoggedInShopByContentProps>(({ data, frameType }, ref) => {
	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);

	const handleClick = direction => {
		if (direction === 'left') {
			(ref as MutableRefObject<HTMLDivElement>).current.scrollBy({ left: -180, behavior: 'smooth' });
		} else {
			(ref as MutableRefObject<HTMLDivElement>).current.scrollBy({ left: 180, behavior: 'smooth' });
		}
	};

	useEffect(() => {
		const container = (ref as MutableRefObject<HTMLDivElement>).current;
		if (container) {
			const handleScroll = () => {
				const isBeginning = container.scrollLeft === 0;
				const isEnd = container.scrollLeft + container.clientWidth >= container.scrollWidth;
				setIsBeginning(isBeginning);
				setIsEnd(isEnd);
			};
			handleScroll();
			container.addEventListener('scroll', handleScroll);
			return () => {
				container.removeEventListener('scroll', handleScroll);
			};
		}
	}, [data.length, ref]);

	return (
		<>
			<div ref={ref} className={styles.content} data-tab-carousel-content={frameType}>
				{data.map(item => {
					return (
						<FilterCard
							showSwatch={frameType === 'color'}
							key={`filter-card-${item.label}`}
							label={item.label}
							value={item.value}
						/>
					);
				})}
			</div>
			<Flex gap={3} justify='end' className={styles.buttonContainer}>
				<button className={cn(styles.button, { [styles['disable']]: isBeginning })} onClick={() => handleClick('left')}>
					<Chevron direction='left' />
				</button>
				<button className={cn(styles.button, { [styles['disable']]: isEnd })} onClick={() => handleClick('right')}>
					<Chevron direction='right' />
				</button>
			</Flex>
		</>
	);
});

LoggedInShopByContent.displayName = 'LoggedInShopByContent';

export default LoggedInShopBy;
